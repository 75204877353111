import { createApp } from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./router";

import i18n from './i18n'
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
// import Vuex from 'vuex'

import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import 'element-plus/dist/index.css'
import { GraphQLClient } from 'graphql-request';
// import VueMatomo from 'vue-matomo'

const hygraphClient = new GraphQLClient(
    'https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/cllm4iqkx1aa501t598gggh0e/master'
);


const appInstance = createApp(App);
appInstance.mixin({
    beforeCreate() {
        this.$hygraph = hygraphClient;
    },
})

// appInstance.use(Vuex)
appInstance.use(store);
appInstance.use(i18n)
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(ElementPlus)
// appInstance.use(VueMatomo, {
//     host: 'https://stat.graspil.com',
//     siteId: 1,
// })
appInstance.mount("#app");

import "bootstrap/dist/js/bootstrap.js"

// import bootstrap from 'bootstrap'
//
// var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
// tooltipTriggerList.map(function (tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl)
// })
