import { createRouter, createWebHistory } from "vue-router";
import Tables from "../views/Tables.vue";
import store from '@/store/store.js'
import i18n from "@/i18n";

let siteName = ' - Graspil App'
const routes = [
  {
    path: "/",
    name: "Home",
    // redirect: "/dashboard-default",
    // component: Dashboard,
    component: () => import('@/views/Dashboard/Dashboard.vue'),
    meta: { title: i18n.global.t('Meta.Title.Home') + siteName }
  },
  // {
  //   path: "/dashboard-default",
  //   name: "Dashboard",
  //   component: Dashboard,
  // },
  {
    path: "/users/:user_id?",
    name: "Users",
    component: () => import('@/views/users/UsersList.vue'),
    meta: { title: 'Users - ' + siteName }
  },
  {
    path: "/conversion",
    name: "Conversions",
    component: () => import('@/views/conversions/Conversions_list.vue'),
    meta: { title: 'Conversions - ' + siteName }
  },
  {
    path: "/targets",
    name: "Targets",
    component: () => import('@/views/conversions/Conversion_settings.vue')
  },
  {
    path: "/targets/:id",
    name: "EditTarget",
    component: () => import('@/views/conversions/EditTarget.vue')
  },
  {
    path: "/utm-builder",
    name: "UtmBuilder",
    component: () => import('@/views/UtmBuilder.vue')
  },
  {
    path: "/code4-site",
    name: "CodeForSite",
    component: () => import('@/views/CodeForSite.vue')
  },
  {
    path: "/bots",
    name: "Bots",
    component: () => import('@/views/bots/BotsList.vue')
  },
  {
    path: "/bots/:key",
    name: "Bot",
    // component: Bot,
    component: () => import('@/views/bots/EditBot.vue')
  },
  {
    path: "/bot_upload/:key",
    name: "Bot_upload",
    // component: Bot,
    component: () => import('@/views/bots/ConnectBotUpload.vue')
  },
  {
    path: "/bot/:key",
    name: "BotI",
    // component: Bot,
    component: () => import('@/views/bots/BotPage/BotPage.vue')
  },
  {
    path: "/bot_errors",
    name: "BotErrors",
    component: () => import('@/views/botErrors/ErrorsList.vue')
  },
  {
    path: "/add-bot",
    name: "AddBot",
    // component: AddBot,
    component: () => import('@/views/bots/AddBot.vue')
  },
  {
    path: "/reports/:type_report(visitors|messages_from_user|new_users|left_users|return_users|rejection|session_count|avg_duration_session|avg_events_session|utm_summary|active_users|conversion_summary)", // :type_report(new-users|left-users|return-users|rejection)
    name: "Reports",
    // component: StandartReport,
    component: () => import('@/views/reports/StandartReport.vue')
    // children: [
    //   {
    //     path: "visitors",
    //     params: {
    //       type_report: "visitors",
    //     },
    //     name: "reports-visitors",
    //     component: StandartReport,
    //   }
    // ]
  },
  // {
  //   path: "/reports/visitors",
  //   name: "reports-visitors",
  //   component: StandartReport,
  // },
  // {
  //   path: "/reports/new-users",
  //   name: "reports-new-users",
  //   component: StandartReport,
  // },
  // {
  //   path: "/reports/left-users",
  //   name: "reports-left-users",
  //   component: StandartReport,
  // },
  // {
  //   path: "/reports/return-users",
  //   name: "reports-return-users",
  //   component: StandartReport,
  // },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/tariff",
    name: "Tariff",
    component: () => import('@/views/billing/Billing.vue')
  },
  // {
  //   path: "/virtual-reality",
  //   name: "Virtual Reality",
  //   component: VirtualReality,
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL",
  //   component: RTL,
  // },
  {
    path: "/profile",
    name: "Profile",
    component: () => import('@/views/pofile/Profile.vue')
  },
  {
    path: "/signin",
    name: "Signin",
    // component: Signin,
    component: () => import('@/views/Signin.vue')
  },
  {
    path: "/signup",
    name: "Signup",
    // component: Signup,
    component: () => import('@/views/Signup.vue')
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    // component: Signin,
    component: () => import('@/views/Forgot-password.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach (async (routeTo) => {
  // const publicPages = [];
  // if (publicPages.includes(routeTo.name)) {
  //   store.commit('theme/__set', {name: 'loadingApp', val: false})
  //   return true
  // }
  const noAuthPages = ['Signin', 'Signup', 'ForgotPassword'];

  return await store.dispatch('init/init').then(data => {
    if (data.user && noAuthPages.includes(routeTo.name)) {
      store.commit('theme/__set', {name: 'loadingApp', val: false})
      return { name: 'Home', query: routeTo.query }
    } else if (!data.user && !noAuthPages.includes(routeTo.name)) {
      store.commit('theme/__set', {name: 'loadingApp', val: false})
      return { name: 'Signin', query: routeTo.query }
    } else {
      store.commit('theme/__set', {name: 'loadingApp', val: false})
      return true
    }
  })

  // if (user === null) {
  //
  // }
  // let authProcessing = store.getters['init/getProcessing']
  // console.log(authProcessing)
  // if (authProcessing !== null) {
  //   console.log(authProcessing)
  //   store.getters['init/getProcessing'].then(data => {
  //     console.log(data)
  //     if (data.user === null && authpage) {
  //
  //       return { name: 'Signin' }
  //       // next('/login')
  //     } else if (data.user !== null && !authpage) {
  //       return { name: '/' }
  //       // next('/')
  //     }
  //   }).catch(() => {
  //     if (routeTo.path !== '/error') {
  //       return { name: 'error' }
  //       // next('/error');
  //     }
  //   }).finally(() => {
  //     console.log(12)
  //   })
  // }
  // next();
  // return true
  // }
})

// router.afterEach( async (to) => {
//   document.title = to.meta.title || 'Graspil App'
//   window._paq.push(['trackPageView'])
//   console.log(to.meta.title)
// })


export default router;
