// import Api from '@/helpers/api/api';
// import store from '@/store/store'

// const nameVariableForLS = "newBotSetting"

const defaultSettings = {
    bot_name: '',
    token: null,
    has_token: null,
    type_platform: null,
    platform: null,
    over_platform: '',
    riskOk: false,
    webhook: null,
    delivery_method: null,
    delivery_method_send: null,
    key: null,
    status: null,
    status_send: null,
    secret_token: null,
    allowed_updates: null,
    api_key: null,
    testResult: null,
    statusDisable: null,
    new_bot: true,
    actualDataInTg: null
}

// const newBotSetting = JSON.parse(localStorage.getItem(nameVariableForLS));

export default {
    namespaced: true,
    state: defaultSettings, // newBotSetting ??
    actions: {
    },
    getters: {
    },
    mutations: {
        /**
         * {name: 'properties', val: 'value'}
         * @param state
         * @param name
         * @param val
         * @private
         */
        __set(state, {name, val}) {
            state[name] = val
            const data = {}
            for (let i in state) {
                if (i === 'token') {
                    continue
                }
                data[i] = state[i]
            }
            // const data = {
            //     typePlatform: state.typePlatform,
            //     platform: state.platform,
            //     overPlatform: state.overPlatform,
            //     riskOk: state.riskOk
            // }
            // localStorage.setItem(nameVariableForLS, JSON.stringify(data))
        },
        resetData(state) {
            for (let i in state) {
                state[i] = defaultSettings[i] ?? null
            }
            // for (let i in defaultSettings) {
            //     state[i] = defaultSettings[i]
            // }
            // localStorage.setItem(nameVariableForLS, JSON.stringify({}))
        },
        loadData(state, data) {
            if (data?.token) {
                state.token = data.token
                delete data.token
            }

            for (let i in data) {
                state[i] = data[i]
            }
            // localStorage.setItem(nameVariableForLS, JSON.stringify(data))
        }
    }

}

