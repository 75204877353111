<template>
  <div
    v-show="this.$store.state.theme.layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${this.$store.state.theme.darkMode ? 'bg-transparent' : 'bg-success'}`"
  />
  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="`${
      this.$store.state.theme.isRTL
        ? 'me-3 rotate-caret fixed-end'
        : 'fixed-start ms-3'
    } 
    ${
      this.$store.state.theme.layout === 'landing'
        ? 'bg-transparent shadow-none'
        : ' '
    } ${this.$store.state.theme.sidebarType}`"
    id="sidenav-main"
  >
<!--    <div class="sidenav-header">-->
<!--      <i-->
<!--        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"-->
<!--        aria-hidden="true"-->
<!--        id="iconSidenav"-->
<!--      ></i>-->
<!--      <router-link class="m-0 navbar-brand d-flex justify-content-center" to="/">-->
<!--        <img-->
<!--          :src="-->
<!--            this.$store.state.theme.darkMode ||-->
<!--            this.$store.state.theme.sidebarType === 'bg-default'-->
<!--              ? logoWhite-->
<!--              : logo-->
<!--          "-->
<!--          class="navbar-brand-img h-100"-->
<!--          alt="main_logo"-->
<!--        />-->
<!--      </router-link>-->
<!--    </div>-->

    <div class="d-flex d-xl-none justify-content-end ">
      <i @click="$store.commit('theme/navbarMinimize')" class="fas fa-times-circle fa-lg me-2 mt-2"></i>
    </div>

    <div class="mt-0 mt-lg-3 d-flex justify-content-start w-100 justify-content-lg-center m">

      <div v-if="!$store.getters['init/getHasDemobot'] && Object.keys($store.state.init.user_bots).length" class="dropdown w-75">
        <a class="btn btn-link dropdown-toggle w-100" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">

          <span v-if="$store.state.init.current_bot">
            {{$store.state.init.user_bots[$store.state.init.current_bot.key].bot_name}}
            <el-tooltip v-if="!$store.state.init.user_bots[$store.state.init.current_bot.key].status" :content="$t('General.Bots.BotNotConnected')" popper-style="width: 300px" raw-content>
              <i  class="fas fa-exclamation-triangle text-warning "></i>
            </el-tooltip>

          </span>
        </a>

        <ul class="dropdown-menu mt-0 w-100 dropdown-menu-dark" aria-labelledby="dropdownMenuLink">
          <li
            v-for="item in  this.$store.state.init.user_bots"
            :key="item.id"
          >
            <a class="dropdown-item" href="#"
               @click.stop="this.$store.commit('init/setCurrentBot', item)"
            >
              {{item.bot_name}}
              <i v-if="!item.status" class="fas fa-exclamation-triangle text-warning"></i>
            </a>
          </li>
        </ul>
      </div>
      <div v-else>
        <router-link to="/add-bot" class="btn btn-link">
          {{$t("General.Bots.AddBot")}}
        </router-link>
      </div>
    </div>

    <hr class="mt-0 horizontal dark" />

    <sidenav-list :cardBg="custom_class" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-dark.png";
import logoWhite from "@/assets/img/logo-white.png";

export default {
  name: "index",
  components: {
    SidenavList
  },
  data() {
    return {
      logo,
      logoWhite
    };
  },
  props: ["custom_class", "layout"]
};
</script>
