<template>

  <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
  >
    <el-menu-item index="1" @click="this.$router.push('/'); $store.commit('theme/navbarHidden')">
      <el-icon><DataBoard /></el-icon>
      <template #title>{{$t("Menu.Dashboard")}}</template>
    </el-menu-item>

    <el-menu-item index="5" @click="this.$router.push('/users'); $store.commit('theme/navbarHidden')">
      <i class="fas fa-users me-2"></i>
      <template #title>
        {{$t("Menu.Users")}}
<!--        <i class="fas fa-star ms-2 small"></i>-->
      </template>
    </el-menu-item>

    <el-menu-item index="3" @click="this.$router.push('/bots'); $store.commit('theme/navbarHidden')">
      <i class="fas fa-robot mr-2"></i>
      <template #title>{{$t("Menu.Bots")}}</template>
    </el-menu-item>

    <el-menu-item index="4" @click="this.$router.push('/utm-builder'); $store.commit('theme/navbarHidden')">
      <i class="fas fa-tools mr-2"></i>
      <template #title>{{$t("Menu.UTMBuilder")}}</template>
    </el-menu-item>

    <el-menu-item index="6" @click="this.$router.push('/code4-site'); $store.commit('theme/navbarHidden')">
      <i class="fas fa-code mr-2"></i>
      <template #title>{{$t("Menu.CodeForSite")}}</template>
    </el-menu-item>

    <el-menu-item index="7" @click="this.$router.push('/bot_errors'); $store.commit('theme/navbarHidden')">
      <i class="fas fa-bug mr-2"></i>
      <template #title>{{$t("Menu.BotErrors")}}</template>
    </el-menu-item>

    <el-sub-menu index="8">
      <template #title>
        <i class="fas fa-percentage mr-2"></i>
        <span>{{$t("Menu.Conversions")}}</span>
      </template>

      <el-menu-item  index="8-1" @click="this.$router.push('/conversion'); $store.commit('theme/navbarHidden')">{{$t("Menu.ConversionsList")}}</el-menu-item>
      <el-menu-item  index="8-2" @click="this.$router.push('/reports/conversion_summary'); $store.commit('theme/navbarHidden')">{{$t("Menu.ConversionsSummary")}}</el-menu-item>
      <el-menu-item  index="8-3" @click="$router.push({name: 'Targets'}); $store.commit('theme/navbarHidden')">{{$t("Menu.TargetSettings")}}</el-menu-item>
    </el-sub-menu>

    <el-sub-menu index="2">
      <template #title>
        <el-icon><location /></el-icon>
        <span>{{$t("Menu.Reports")}}</span>
      </template>

<!--      <el-menu-item index="2-1" @click="this.$router.push('/reports/visitors')">{{$t("Menu.TotalMessages")}}</el-menu-item>-->

      <el-menu-item-group>
        <template #title><span>{{$t("Menu.Behaviour")}}</span></template>
<!--        <el-menu-item  index="2-11" @click="this.$router.push('/reports/active_users'); $store.commit('theme/navbarHidden')">{{$t("Menu.ActiveUsers")}}</el-menu-item>-->

        <el-sub-menu index="2-11">
          <template #title>{{$t("Menu.ActiveUsers")}}</template>
          <el-menu-item  index="2-11-1" @click="this.$router.push('/reports/active_users?details=month'); $store.commit('theme/navbarHidden')">MAU</el-menu-item>
          <el-menu-item  index="2-11-2" @click="this.$router.push('/reports/active_users?details=week'); $store.commit('theme/navbarHidden')">WAU</el-menu-item>
          <el-menu-item  index="2-11-3" @click="this.$router.push('/reports/active_users?details=day'); $store.commit('theme/navbarHidden')">DAU</el-menu-item>
        </el-sub-menu>

        <el-menu-item  index="2-2" @click="this.$router.push('/reports/messages_from_user'); $store.commit('theme/navbarHidden')">{{$t("Menu.TotalReceivedMessages")}}</el-menu-item>
        <el-menu-item  index="2-6" @click="this.$router.push('/reports/rejection'); $store.commit('theme/navbarHidden')">{{$t("Menu.Rejection")}}</el-menu-item>
      </el-menu-item-group>

      <el-menu-item-group>
        <template #title><span>{{$t("Menu.Sources")}}</span></template>
        <el-menu-item index="2-10" @click="this.$router.push('/reports/utm_summary'); $store.commit('theme/navbarHidden')">{{$t("Menu.UtmSummary")}}</el-menu-item>
      </el-menu-item-group>


      <el-menu-item-group>
        <template #title><span>{{$t("Menu.Users")}}</span></template>

        <el-menu-item index="2-3" @click="this.$router.push('/reports/new_users'); $store.commit('theme/navbarHidden')">{{$t("Menu.NewUsers")}}</el-menu-item>
        <el-menu-item index="2-4" @click="this.$router.push('/reports/left_users'); $store.commit('theme/navbarHidden')">{{$t("Menu.LeftUsers")}}</el-menu-item>
        <el-menu-item index="2-5" @click="this.$router.push('/reports/return_users'); $store.commit('theme/navbarHidden')">{{$t("Menu.ReturnUsers")}}</el-menu-item>
      </el-menu-item-group>



      <el-menu-item-group>
        <template #title><span>{{$t("Menu.SessionsTitle")}}</span></template>
        <el-menu-item index="2-7" @click="this.$router.push('/reports/session_count'); $store.commit('theme/navbarHidden')">{{$t("Menu.Sessions")}}</el-menu-item>
        <el-menu-item index="2-9" @click="this.$router.push('/reports/avg_events_session'); $store.commit('theme/navbarHidden')">{{$t("Menu.AvgEventsSession")}}</el-menu-item>
        <el-menu-item index="2-8" @click="this.$router.push('/reports/avg_duration_session'); $store.commit('theme/navbarHidden')">{{$t("Menu.AvgDurationSession")}}</el-menu-item>
      </el-menu-item-group>

<!--      <el-menu-item-group title="Group Two">-->
<!--        <el-menu-item index="1-3">item three</el-menu-item>-->
<!--      </el-menu-item-group>-->
<!--      <el-sub-menu index="1-4">-->
<!--        <template #title><span>item four</span></template>-->
<!--        <el-menu-item index="1-4-1">item one</el-menu-item>-->
<!--      </el-sub-menu>-->
    </el-sub-menu>

  </el-menu>


<!--  <div-->
<!--    class="collapse navbar-collapse w-auto h-auto h-100"-->
<!--    id="sidenav-collapse-main"-->
<!--  >-->
<!--    <ul class="navbar-nav">-->
<!--      <li class="nav-item">-->
<!--        <sidenav-item-->
<!--          url="/dashboard-default"-->
<!--          :class="getRoute() === 'dashboard-default' ? 'active' : ''"-->
<!--          :navText="this.$store.state.theme.isRTL ? 'لوحة القيادة' : 'Dashboard'"-->
<!--        >-->
<!--          <template v-slot:icon>-->
<!--            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>-->
<!--          </template>-->
<!--        </sidenav-item>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <sidenav-item-->
<!--          url="/tables"-->
<!--          :class="getRoute() === 'tables' ? 'active' : ''"-->
<!--          :navText="this.$store.state.theme.isRTL ? 'الجداول' : 'Tables'"-->
<!--        >-->
<!--          <template v-slot:icon>-->
<!--            <i-->
<!--              class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"-->
<!--            ></i>-->
<!--          </template>-->
<!--        </sidenav-item>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <sidenav-item-->
<!--          url="/billing"-->
<!--          :class="getRoute() === 'billing' ? 'active' : ''"-->
<!--          :navText="this.$store.state.theme.isRTL ? 'الفواتیر' : 'Billing'"-->
<!--        >-->
<!--          <template v-slot:icon>-->
<!--            <i class="ni ni-credit-card text-success text-sm opacity-10"></i>-->
<!--          </template>-->
<!--        </sidenav-item>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <sidenav-item-->
<!--          url="/virtual-reality"-->
<!--          :class="getRoute() === 'virtual-reality' ? 'active' : ''"-->
<!--          :navText="-->
<!--            this.$store.state.theme.isRTL ? 'الواقع الافتراضي' : 'Virtual Reality'-->
<!--          "-->
<!--        >-->
<!--          <template v-slot:icon>-->
<!--            <i class="ni ni-app text-info text-sm opacity-10"></i>-->
<!--          </template>-->
<!--        </sidenav-item>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <sidenav-item-->
<!--          url="/rtl-page"-->
<!--          :class="getRoute() === 'rtl-page' ? 'active' : ''"-->
<!--          navText="RTL"-->
<!--        >-->
<!--          <template v-slot:icon>-->
<!--            <i class="ni ni-world-2 text-danger text-sm opacity-10"></i>-->
<!--          </template>-->
<!--        </sidenav-item>-->
<!--      </li>-->
<!--      <li class="mt-3 nav-item">-->
<!--        <h6-->
<!--          v-if="this.$store.state.theme.isRTL"-->
<!--          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"-->
<!--          :class="this.$store.state.theme.isRTL ? 'me-4' : 'ms-2'"-->
<!--        >-->
<!--          صفحات المرافق-->
<!--        </h6>-->
<!--        <h6-->
<!--          v-else-->
<!--          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"-->
<!--          :class="this.$store.state.theme.isRTL ? 'me-4' : 'ms-2'"-->
<!--        >-->
<!--          ACCOUNT PAGES-->
<!--        </h6>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <sidenav-item-->
<!--          url="/profile"-->
<!--          :class="getRoute() === 'profile' ? 'active' : ''"-->
<!--          :navText="this.$store.state.theme.isRTL ? 'حساب تعريفي' : 'Profile'"-->
<!--        >-->
<!--          <template v-slot:icon>-->
<!--            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>-->
<!--          </template>-->
<!--        </sidenav-item>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <sidenav-item-->
<!--          url="/signin"-->
<!--          :class="getRoute() === 'signin' ? 'active' : ''"-->
<!--          :navText="this.$store.state.theme.isRTL ? 'تسجيل الدخول' : 'Sign In'"-->
<!--        >-->
<!--          <template v-slot:icon>-->
<!--            <i class="ni ni-single-copy-04 text-danger text-sm opacity-10"></i>-->
<!--          </template>-->
<!--        </sidenav-item>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <sidenav-item-->
<!--          url="/signup"-->
<!--          :class="getRoute() === 'signup' ? 'active' : ''"-->
<!--          :navText="this.$store.state.theme.isRTL ? 'اشتراك' : 'Sign Up'"-->
<!--        >-->
<!--          <template v-slot:icon>-->
<!--            <i class="ni ni-collection text-info text-sm opacity-10"></i>-->
<!--          </template>-->
<!--        </sidenav-item>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </div>-->
<!--  <div class="pt-3 mx-3 mt-3 sidenav-footer">-->
<!--    <sidenav-card-->
<!--      :class="cardBg"-->
<!--      textPrimary="Need Help?"-->
<!--      textSecondary="Please check our docs"-->
<!--    />-->
<!--  </div>-->
</template>
<script>
// import SidenavItem from "./SidenavItem.vue";
// import SidenavCard from "./SidenavCard.vue";

import {
  // Menu as IconMenu,
  Location,
  DataBoard
} from '@element-plus/icons-vue'

// import { ElDatePicker } from 'element-plus'
//  el-menu el-menu-item el-menu-item-grou
export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Argon Dashboard 2",
      controls: "dashboardsExamples",
      isActive: "active"
    };
  },
  components: {
    // SidenavItem,
    // SidenavCard,
    // IconMenu,
    Location,
    DataBoard
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>
<style>
.el-sub-menu .el-menu-item {
  line-height: 40px !important;
  height: 40px !important;
}
</style>
