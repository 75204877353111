import { createStore } from 'vuex'

import theme from './modules/theme'
import moduleInit from './modules/init'
import botSetting from './modules/botSetting'
import chat from './modules/chat'
import botProfile from './modules/botProfile'

// const store = new Vuex.Store({
//   modules: {
//     init: moduleInit
//   },
//   // Enable strict mode in development to get a warning
//   // when mutating state outside of a mutation.
//   // https://vuex.vuejs.org/guide/strict.html
//   strict: process.env.NODE_ENV !== 'production',
// })

const store = createStore({
  modules: {
    theme: theme,
    init: moduleInit,
    botSetting: botSetting,
    chat,
    botProfile
  },
  // state:{},
  // mutations:{},
  // actions:{},
  strict: process.env.NODE_ENV !== 'production'
});

store.dispatch('init/init').then(() => {}).catch(() => {});

export default store

